import React, { useState } from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { Button, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, NativeSelect, Switch} from '@material-ui/core';
import { AllModules } from 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css'

import moment from 'moment';

import bscScanIcon from './images/bscscan.svg';
import dextoolsIcon from './images/dextools.png';
import pancakeSwapIcon from './images/pancakeswap.svg';
import poocoinIcon from './images/poocoin.svg';

import BlacklistModal from './BlacklistModal';
import SimpleSnackbar from "./Snackbar";

const PNLGrid = props => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [blacklistSnackbarMessage, setBlacklistSnackbarMessage] = useState('');
    const [lastBlacklistedToken, setLastBlacklistedToken] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const numberComparator = (a, b) => a - b

    const dynamicPercentageStyle = params => {
        if (Number(params.value) < 0) {
            return {color: 'Tomato'};
        } else if (Number(params.value) === 0.0) {
            return {color: 'white'};
        } else {
            return {color: '#03C03C'};
        }
    };

    const dynamicTokenNameStyle = params => {
        const buyTime = params.data['Buy Time'] * 1000

        if (buyTime && buyTime > moment().subtract(1,'d').valueOf()) {
            return {color: '#EEE8AA'}
        }
    }

    const percentFormatter = params => {
        return params.value !== null ? params.value.toFixed(0) + '%' : ''
    }

    const numberFormatter = params => {
        return params.value !== null ? params.value.toFixed(2) : ''
    }

    const timeFormatter = params => {
        return params.value ? moment(params.value * 1000).fromNow() : ''
    }

    const onFirstDataRendered = (params) => {
        let allColumnIds = [];
        params.columnApi.getAllColumns().forEach(function(column) {
            allColumnIds.push(column.colId);
        });

        params.columnApi.autoSizeColumns(allColumnIds);

        // we're sorting here instead of setting it in column def
        // to avoid conflicts with quick sorts where Realizable sort overrides other sorts
        sortByColumn('Realizable', params.columnApi)
    };

    const linksCellRenderer = (params) => {
        if(params.data.Address) {
            return `
                <div>
                    <a href=https://bscscan.com/address/${params.data.Address}#code target='_blank' rel='noreferrer'>
                        <img class='external-icon' src=${bscScanIcon} title="BSC Scan">
                    </a>
                    <a href=https://www.dextools.io/app/pancakeswap/pair-explorer/${params.data['Pair Address']} target='_blank' rel='noreferrer'>
                        <img class='external-icon' src=${dextoolsIcon} title="DexTools">
                    </a>
                    <a href=https://poocoin.app/tokens/${params.data.Address} target='_blank' rel='noreferrer'>
                        <img class='external-icon' src=${poocoinIcon} title="Poocoin">
                    </a>
                    <a href=https://exchange.pancakeswap.finance/#/swap?outputCurrency=${params.data.Address} target='_blank' rel='noreferrer'>
                        <img class='external-icon' src=${pancakeSwapIcon} title="PancakeSwap">
                    </a>
                </div>
            `
        } else {
            return ''
        }
    }

    const getTotals = () => {
        return [props.tokenData.totalsObj]
    }

    const getRowNodeId = (data) => data['Address'];

    const defaultColDef = {
        sortable: true,
        resizable: true,
        enableCellChangeFlash: true,
    };

    const gridOptions = {
        tooltipShowDelay: 500,
    }

    const createChart = () => {
        const params = {
            cellRange: {
                rowStartIndex: 0,
                rowEndIndex: 10,
                columns: ['Token', 'In', 'Out', 'Realizable'],
            },
            chartType: 'normalizedColumn',
            chartThemeName: 'ag-pastel',
            chartThemeOverrides: {
                common: {
                    title: {
                        enabled: true,
                        text: 'Normalized Summary of top tokens',
                        color: 'white',
                    },
                    background: {
                        fill: '#303030',
                    },
                    legend: {
                        enabled: true,
                        item: {
                            label: {
                                color: 'white',
                            },
                        }
                    },
                    axes: {
                        number: {
                            label: {
                                color: 'white',
                            },
                        },
                        log: {
                            label: {
                                color: 'white',
                            },
                        },
                        category: {
                            label: {
                                color: 'white',
                            },
                        },
                        time: {
                            label: {
                                color: 'white',
                            },
                        }
                    }
                },
            },
        };
        gridApi.createRangeChart(params);
    };

    const getRowData = () => {
        if(gridApi) {
            gridApi.refreshCells({columns: ['Buy Time'], force: true})
        }

        return props.tokenData.whitelist
    }

    const sortByMostRisk = () => {
        gridColumnApi.applyColumnState({
            state: [
                {
                    colId: 'YOLO %',
                    sort: 'desc',
                    sortIndex: 0,
                },
                {
                    colId: 'Realizable',
                    sort: 'desc',
                    sortIndex: 1,
                },
            ],
            defaultState: { sort: null },
        });
    };

    const sortByColumn = (columnToSortBy, columnApi = gridColumnApi) => {
        columnApi.applyColumnState({
            state: [
                {
                    colId: columnToSortBy,
                    sort: 'desc',
                    sortIndex: 0,
                }
            ],
            defaultState: { sort: null },
        });
    };

    const recentLosersFilter = () => {
        const buyTimeFilter = gridApi.getFilterInstance('Buy Time')
        const realizablePctFilter = gridApi.getFilterInstance('Realizable %')
        let dateFilter = new Date()
        dateFilter.setDate(dateFilter.getDate() - 2)

        let isoStringDate = dateFilter.toISOString().substring(0,10)

        console.log('filter will be: ', isoStringDate)
        console.log('2021-08-14')
        console.log('2021-08-14' === isoStringDate)

        buyTimeFilter.setModel({
            filterType: 'date',
            type: 'greaterThan',
            dateFrom: isoStringDate
        });
    
        realizablePctFilter.setModel({
            filterType: 'number',
            type: 'lessThan',
            filter: '-69'
        })

        gridColumnApi.applyColumnState({
            state: [
                {
                    colId: 'Net Realizable',
                    sort: 'asc',
                    sortIndex: 0,
                },
                {
                    colId: 'Realizable %',
                    sort: 'asc',
                    sortIndex: 1,
                },
            ],
            defaultState: { sort: null },
        });
    
        gridApi.onFilterChanged();
    };

    const filterDateParams = {
        // todo gonna have to deal with timezones here lol
        comparator: function (filterLocalDateAtMidnight, cellValue) {
            console.log('comparator')
            console.log(`filterLocalDateAtMidnight: ${filterLocalDateAtMidnight}, cellValue: ${cellValue}`)

            const filterUnix = new Date(filterLocalDateAtMidnight).getTime() / 1000

            if (filterUnix === cellValue) {
                return 0;
            }
            if (cellValue < filterUnix) {
                return -1;
            }
            if (cellValue > filterUnix) {
                return 1;
            }
        },
        browserDatePicker: true,
        minValidYear: 2000,
    };

    const handleQuickSortChange = event => {
        const sortBy = event.target.value
        if (sortBy === 'Risk') {
            sortByMostRisk()
        } else {
            sortByColumn(sortBy)
        }
    }

    const handleInactiveTokenSwitchChange = event => {
        props.toggleFilterInactiveTokens()
    }

    const handleBlacklistedTokenSwitchChange = event => {
        props.toggleFilterBlacklistedTokens()
    }

    const getContextMenuItems = (params) => {
        const result = [
            {
                name: 'Blacklist Token',
                action: function () {
                    const tokenAddressToBlacklist = params.node.data.Address
                    setBlacklistSnackbarMessage(
                        {
                            'message': `Blacklisted "${params.node.data['Token']}"`,
                            'date': new Date()
                        }
                    );
                    props.addTokenToBlacklist(tokenAddressToBlacklist)
                    setLastBlacklistedToken(tokenAddressToBlacklist)
                },
            },
            'copy',
        ];
        return result;
    };

    const undoLastBlacklistedToken = () => {
        props.removeTokenFromBlacklist(lastBlacklistedToken)
    }

    return (
        <div style={{width: '100vw'}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FormControl className={'quick-sort'}>
                        <InputLabel htmlFor="select">Quick Sort:</InputLabel>
                        <NativeSelect onChange={handleQuickSortChange}>
                            <option value="Remaining">Remaining</option>
                            <option value="Realizable">Realizable</option>
                            <option value="Risk">Risk</option>
                            <option value="60m">Biggest Gainers</option>
                            <option value="Buy Time">Recent Purchases</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <BlacklistModal
                        addTokenToBlacklist={props.addTokenToBlacklist}
                        removeTokenFromBlacklist={props.removeTokenFromBlacklist}
                        blacklistedTokens={props.tokenData.blacklist}
                    />
                </Grid>

                <Grid item xs={12} sm={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button variant="outlined" onClick={() => recentLosersFilter()}>
                        Recent Losers
                    </Button>
                </Grid>

                {/* <Grid item xs={12} sm={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button variant="outlined" onClick={() => createChart()}>
                        Create Chart
                    </Button>
                </Grid> */}

                <Grid item xs={12} sm={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FormGroup row style={{display: 'flex', flexDirection: 'column', alignItems: 'right'}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.filterInactiveTokens}
                                    onChange={handleInactiveTokenSwitchChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Filter Inactive Tokens"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.filterBlacklistedTokens}
                                    onChange={handleBlacklistedTokenSwitchChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Filter Blacklisted Tokens"
                        />
                    </FormGroup>
                </Grid>
                {/*/!*<div onClick={() => filterOlderThanToday()} className="basic-button">Today Only</div>*!/*/}
            </Grid>

            {  blacklistSnackbarMessage
                ? <SimpleSnackbar
                    key={blacklistSnackbarMessage.date}
                    message={blacklistSnackbarMessage.message}
                    undo={undoLastBlacklistedToken}
                />
                : null
            }

            <div className={"ag-theme-balham-dark"} style={{ height: '100vh', marginTop: '2em'}}>
                <div style={{height: '100%', width: '100%'}}>
                    <AgGridReact
                        modules={AllModules}
                        rowData={getRowData()}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridReady={onGridReady}
                        defaultColDef={defaultColDef}
                        pinnedTopRowData={getTotals()}
                        getRowNodeId={getRowNodeId}
                        immutableData={true}
                        enableRangeSelection={true}
                        enableCharts={true}
                        animateRows={true}
                        // by default ag-grid only renders on screen columns
                        // but our column autosizing can only act on rendered columns
                        // this setting renders all columns so they can be autosized
                        suppressColumnVirtualisation={true}
                        getContextMenuItems={getContextMenuItems}
                        gridOptions={gridOptions}
                    >
                        <AgGridColumn
                            field="Token"
                            cellStyle={dynamicTokenNameStyle}
                            pinned='left'
                            filter="agTextColumnFilter"
                            maxWidth={150}
                        />
                        <AgGridColumn
                            field="In"
                            filter="agNumberColumnFilter"
                            valueFormatter={numberFormatter}
                            comparator={numberComparator}
                            type="rightAligned"
                            headerTooltip="BNB invested in a token."
                        />
                        <AgGridColumn
                            field="Out"
                            filter="agNumberColumnFilter"
                            valueFormatter={numberFormatter}
                            comparator={numberComparator}
                            type="rightAligned"
                            headerTooltip="BNB cashed out of a token."
                        />
                        <AgGridColumn
                            field="Net"
                            filter="agNumberColumnFilter"
                            valueFormatter={numberFormatter}
                            comparator={numberComparator}
                            type="rightAligned"
                            headerTooltip="The difference in BNB cashed out vs put in."
                        />
                        <AgGridColumn
                            field="Remaining"
                            filter="agNumberColumnFilter"
                            valueFormatter={numberFormatter}
                            comparator={numberComparator}
                            type="rightAligned"
                            headerTooltip="The BNB value of all your tokens (Price impact NOT included)."
                        />
                        <AgGridColumn
                            field="Realizable"
                            filter="agNumberColumnFilter"
                            valueFormatter={numberFormatter}
                            comparator={numberComparator}
                            type="rightAligned"
                            headerTooltip="How much BNB you would obtain if you sold your entire position (Price impact included)."
                        />
                        <AgGridColumn
                            field="Net Realizable"
                            filter="agNumberColumnFilter"
                            valueFormatter={numberFormatter}
                            comparator={numberComparator}
                            type="rightAligned"
                            headerTooltip="How much BNB you would obtain if you sold your entire position, plus what you already sold, minus what you paid for the position (Price impact included)."
                        />
                        <AgGridColumn
                            field="Realized %"
                            filter="agNumberColumnFilter"
                            cellStyle={dynamicPercentageStyle}
                            valueFormatter={percentFormatter}
                            comparator={numberComparator}
                            headerTooltip="The ratio of how much BNB you've cashed out to how much you've invested."
                        />
                        <AgGridColumn
                            field="Realizable %"
                            filter="agNumberColumnFilter"
                            cellStyle={dynamicPercentageStyle}
                            valueFormatter={percentFormatter}
                            comparator={numberComparator}
                            headerTooltip="The ratio of how much BNB you've cashed out and can still cash out to how much you've invested."
                        />
                        <AgGridColumn
                            field="YOLO %"
                            filter="agNumberColumnFilter"
                            valueFormatter={percentFormatter}
                            comparator={numberComparator}
                            headerTooltip="The ratio of how much BNB you can still cash out compared to how much you already cashed out. A general indicator of how risky your current position is."
                        />
                        <AgGridColumn
                            field="5m"
                            filter="agNumberColumnFilter"
                            cellStyle={dynamicPercentageStyle}
                            valueFormatter={percentFormatter}
                            comparator={numberComparator}
                            headerTooltip="Price change percent in the last 5 minutes."
                        />
                        <AgGridColumn
                            field="10m"
                            filter="agNumberColumnFilter"
                            cellStyle={dynamicPercentageStyle}
                            valueFormatter={percentFormatter}
                            comparator={numberComparator}
                            headerTooltip="Price change percent in the last 10 minutes."
                        />
                        <AgGridColumn
                            field="30m"
                            filter="agNumberColumnFilter"
                            cellStyle={dynamicPercentageStyle}
                            valueFormatter={percentFormatter}
                            comparator={numberComparator}
                            headerTooltip="Price change percent in the last 30 minutes."
                        />
                        <AgGridColumn
                            field="60m"
                            filter="agNumberColumnFilter"
                            cellStyle={dynamicPercentageStyle}
                            valueFormatter={percentFormatter}
                            comparator={numberComparator}
                            headerTooltip="Price change percent in the last 60 minutes."
                        />
                        <AgGridColumn
                            field="Buy Time"
                            comparator={numberComparator}
                            valueFormatter={timeFormatter}
                            filter="agDateColumnFilter"
                            filterParams={filterDateParams}
                            enableCellChangeFlash={false}
                        />
                        <AgGridColumn
                            field="Address"
                            maxWidth={100}
                        />
                        <AgGridColumn
                            field="Links"
                            cellRenderer={linksCellRenderer}
                            minWidth={155}
                            flex={1}
                        />
                    </AgGridReact>
                </div>
            </div>
        </div>
    )
}

export default PNLGrid;