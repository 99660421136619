import React from 'react'
import Grid from '@material-ui/core/Grid';

import WalletSearchbar from './WalletSearchbar'

import './index.css'

const PortfolioDetails = (props) => {
    const usdFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <h1 style={{textAlign: 'center'}}>Portfolio Total: {props.portfolioTotal.toFixed(2)} BNB ({usdFormatter.format(props.portfolioTotal * props.bnbPrice)})</h1>
            </div>
            <Grid container spacing={1} style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
                <Grid item xs={12} sm={6} spacing={1}>
                    <div>
                        Wallet Balance
                        {props.wallets.map((wallet) =>
                            <p key={wallet.address}>  
                                <a href={`https://bscscan.com/address/${wallet.address}`} target='_blank' rel='noreferrer'>{wallet.address}</a>: {Number(wallet.balance).toFixed(2)} BNB
                            </p>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} spacing={1}>
                    <p className='last-refresh' style={{textAlign: 'right'}}>
                        Last Refresh:
                        <span style={{width: '1.25em', display: 'block', float: 'right', clear: 'right'}}>
                            {props.secondsSinceRefresh}
                        </span>
                    </p>
                </Grid>
            </Grid>
            <div>
                <WalletSearchbar />
            </div>
        </div>
    )
}


export default PortfolioDetails;