import {
	makeApiRequest,
	generateSymbol,
	parseFullSymbol,
	makeApiRequestApeTools,
} from './helpers.js';



const lastBarsCache = new Map();

const configurationData = {
	supported_resolutions: ['1', '5', '15', 'D'],
	exchanges: [{
		value: 'Bitfinex',
		name: 'Bitfinex',
		desc: 'Bitfinex',
	},
	{
		// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
		value: 'Kraken',

		// filter name
		name: 'Kraken',

		// full exchange name displayed in the filter popup
		desc: 'Kraken bitcoin exchange',
	},
	],
	symbols_types: [{
		name: 'crypto',

		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'crypto',
	},
		// ...
	],
};

async function getTokenData(tokenAddress) {
	const tokenData = await makeApiRequest(`api/token/${tokenAddress}`);

	return tokenData;
}

export default {
	onReady: (callback) => {
		console.log('[onReady]: Method call');
		setTimeout(() => callback(configurationData));
	},

	// searchSymbols: async (
	// 	userInput,
	// 	exchange,
	// 	symbolType,
	// 	onResultReadyCallback,
	// ) => {
	// 	console.log('[searchSymbols]: Method call');
	// 	const symbols = await getAllSymbols();
	// 	const newSymbols = symbols.filter(symbol => {
	// 		const isExchangeValid = exchange === '' || symbol.exchange === exchange;
	// 		const isFullSymbolContainsInput = symbol.full_name
	// 			.toLowerCase()
	// 			.indexOf(userInput.toLowerCase()) !== -1;
	// 		return isExchangeValid && isFullSymbolContainsInput;
	// 	});
	// 	onResultReadyCallback(newSymbols);
	// },

	resolveSymbol: async (
		tokenAddress,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
	) => {
		// TODO get this info from our own API
		console.log('[resolveSymbol]: Method call', tokenAddress);
		const tokenDetails = await getTokenData(tokenAddress);

		const symbolInfo = {
			ticker: tokenDetails.tokenSymbol,
			name: `${tokenDetails.tokenSymbol}/BNB`,
			description: `${tokenDetails.tokenSymbol}/BNB`,
			type: 'crypto',
			session: '24x7',
			timezone: 'Etc/UTC',
			exchange: 'ApeTools',
			minmov: 1,
			pricescale: 100000000000000,
			has_intraday: true,
			has_no_volume: true,
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: 'streaming',
		};

		console.log('[resolveSymbol]: Symbol resolved', tokenAddress);
		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest } = periodParams;
		console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
		const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
		let resolutionToMinutes = {
			"1": 1,
			"5": 5,
			"15": 15,
			"1D": 60 * 24
		}

		// get token from url params
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		const pathNameParts = window.location.pathname.split('/')
		const tokenAddress = pathNameParts[pathNameParts.length - 1];
		console.log('token address: ', tokenAddress)

		const urlParameters = {
			e: parsedSymbol.exchange,
			fsym: parsedSymbol.fromSymbol,
			tsym: parsedSymbol.toSymbol,
			toTs: to,
			fromTs: from,
			limit: 2000,
			resolution: resolutionToMinutes[resolution],
			token: tokenAddress,
		};
		const query = Object.keys(urlParameters)
			.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
			.join('&');
		try {
			//const data = await makeApiRequest(`data/histoday?${query}`);
			let url = `https://api.apefund.io/api/candles?${query}`;
			console.log(url);
			const data = await makeApiRequestApeTools(url);
			console.log(data);
			if (data.Response && data.Response === 'Error' || data.length === 0) {
				console.log("No data :(");
				// "noData" should be set if there is no data in the requested period.
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}
			let bars = data;
			bars = bars.map(b => {
				// b.open = b.open * Math.pow(10, 14);
				// b.high = b.high * Math.pow(10, 14);
				// b.low = b.low * Math.pow(10, 14);
				// b.close = b.close * Math.pow(10, 14);

				return b;
			});
			// let bars = [];
			// data.Data.forEach(bar => {
			// 	if (bar.time >= from && bar.time < to) {
			// 		bars = [...bars, {
			// 			time: bar.time * 1000,
			// 			low: bar.low,
			// 			high: bar.high,
			// 			open: bar.open,
			// 			close: bar.close,
			// 		}];
			// 	}
			// });
			console.log("Spittin' BARS");
			console.log(bars);
			if (firstDataRequest) {
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
			}
			console.log(`[getBars]: returned ${bars.length} bar(s)`);
			onHistoryCallback(bars, {
				noData: false,
			});
		} catch (error) {
			console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback,
	) => {
		console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
		// subscribeOnStream(
		// 	symbolInfo,
		// 	resolution,
		// 	onRealtimeCallback,
		// 	subscribeUID,
		// 	onResetCacheNeededCallback,
		// 	lastBarsCache.get(symbolInfo.full_name),
		// );
	},

	unsubscribeBars: (subscriberUID) => {
		console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		//unsubscribeFromStream(subscriberUID);
	},
};
