import React, { useState, useEffect } from 'react';
import { TVChartContainer } from './components/TVChartContainer/index';
import Loading from './Loading'
import axios from 'axios';


const TokenDashboard = (props) => {
    const [tokenData, setTokenData] = useState({})
    // const tokenApiUrl = `http://localhost:8000/api/token/${props.tokenAddress}`
    const tokenApiUrl = `https://api.apefund.io/api/token/${props.tokenAddress}`

    useEffect(() => {
        axios(tokenApiUrl)
            .then(res => {
                console.log(res)
                setTokenData({
                    'tokenName': res.data.tokenName,
                    'tokenSymbol': res.data.tokenSymbol,
                    'marketcap': Number(res.data.marketCap),
                    'totalSupply': res.data.totalSupply,
                    'tokenPrice': res.data.tokenPrice,
                })
            })
            // .catch(err => console.log(err))
    }, [])

    const usdFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 3
    });

    const numberWithCommas = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // make price scientific notation
    return (
        <div className='dashboard-container'>
            {
                tokenData.hasOwnProperty('tokenName') ? 
                    <div style={{width: '100vw'}}>
                        <div className='token-details' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: '1rem'}}>
                            <h1>{tokenData.tokenName}: {usdFormatter.format(tokenData.tokenPrice)}</h1>
                            <div>
                                <div>Market Cap</div>
                                <div>{usdFormatter.format(tokenData.marketcap)}</div>
                            </div>
                            <div>
                                <div>Total Supply</div>
                                <div>{numberWithCommas(tokenData.totalSupply)}</div>
                            </div>
                        </div>
                        <TVChartContainer
                            tokenAddress={props.tokenAddress}
                            setTokenData={setTokenData}
                        />
                    </div> : 
                    <Loading />

            }

        </div>
    )
}

export default TokenDashboard;